<script>
export default {
  name: 'AuthRedirect',
  created() {
    try {
      const { query } = this.$route
      // 检测会员有没有登录
      this.$store.dispatch('user/LoginByThirdparty').then(({ member }) => {
        if (!member.uid) {
          throw new Error('未获取到用户uid，有可能是未设置「粉丝同步」及「同步粉丝，会员信息」')
        } else {
          if (query.redirect) {
            console.log(query.redirect)
            this.$router.replace({ path: query.redirect, query: { sysset_id: query.sysset_id, business_id: query.business_id }})
          } else {
            this.$router.replace({ name: 'home.index' })
          }
        }
      })
    } catch (e) {
      console.error(e)
    }
  },
  render: function(h) {
    return h() // avoid warning message
  }
}
</script>
